
import { mapGetters, mapMutations, mapState } from "vuex";
import LanguageSwitcher from "~/components/common/LanguageSwitcher.vue";
import { getElementHeight, topMenuFormatter } from "~/utils";
import CaretRightSvg from "~/components/svg/CaretRightSvg.vue";
import CloseSvg from "~/components/svg/CloseSvg.vue";
import UserSvg from "~/components/svg/UserSvg.vue";
import PinSvg from "~/components/svg/PinSvg.vue";

export default {
  components: { PinSvg, UserSvg, CloseSvg, CaretRightSvg, LanguageSwitcher },
  props: {
    headerMenu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getLanguages: "general/getLanguages",
      isAuthenticated: "auth/isAuthenticated",
    }),
    ...mapState({
      isOpenMobileMenu: (state) => state.general.isOpenMobileMenu,
      settings: (state) => state.general.settings,
    }),
  },
  watch: {
    isOpenMobileMenu(val) {
      document.body.style.overflow = val ? "hidden" : "auto";
      const chatIcon = document.querySelector("[data-id='zsalesiq']");
      if (chatIcon) {
        chatIcon.style.display = val ? "none" : "block";
      }
    },
  },
  methods: {
    ...mapMutations({
      toggleMobileMenu: "general/TOGGLE_MOBILE_MENU",
    }),
    getElementHeight,
  },
};
